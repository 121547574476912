import { IonContent, IonPage, IonItemSliding, IonItem, IonItemOption, IonLabel, IonIcon, IonItemOptions, IonHeader, IonToolbar, IonText } from '@ionic/react';
import { remove  } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import './PlaylistDetail.css';
import { format, parseISO } from 'date-fns';
import API from '../api';
import { useLocalStorage } from 'usehooks-ts';
import { IonList, IonReorder, IonReorderGroup, ItemReorderEventDetail } from '@ionic/react';

interface PlaylistDetailProps extends RouteComponentProps {
    categories?: any;
    songs?: any;
    playlists?: any;
}

const PlaylistDetail: React.FC<PlaylistDetailProps> = ({match, categories, songs, playlists}) => {
    const [playlistSongs, setPlaylistSongs] = useState<any>(null);
    const [playlist, setPlaylist] = useState<any>(null);
    const [loggedIn] = useLocalStorage('loggedIn', false);

    const getCategoryColor = (categoryId: any) => {
        return categories && categories.find((c: { id: any; }) => c.id === categoryId).color;
    }

    useEffect(() => {
        let params : any = match.params;
        if (params.id && playlist === null && songs && songs?.length !== 0 && playlists && playlists?.length !== 0) {
            const newPlaylist = playlists.find((p: { id: number; }) => p.id === Number(params.id));
            const playlistSongsFiltered = songs.filter((s: { id: number; }) => newPlaylist.song_ids.indexOf(s.id) !== -1);
            const playlistSongsSorted = playlistSongsFiltered.sort((a: any, b: any) => newPlaylist.song_ids.indexOf(a.id) - newPlaylist.song_ids.indexOf(b.id));
            setPlaylistSongs(playlistSongsSorted);
            console.log(newPlaylist);
            setPlaylist(newPlaylist);
        }
    }, [playlist, songs, playlists]);

    const removeSongFromPlaylist = async (song: any) => {        
        const index = playlist.song_ids.indexOf(song.id);
        if (index > -1) { // only splice array when item is found
            playlist.song_ids.splice(index, 1); // 2nd parameter means remove one item only
        }

        API.post("/update/playlist", {
            id: playlist.id,
            name: playlist.name,
            date: playlist.date,
            user_id: 1, 
            songs_id: playlist.song_ids
          })
        .then(async (res: any) => {
            if (res.status === 200) {
                setPlaylist(null);
                await document.querySelector("ion-item-sliding")?.closeOpened();
            }
        })
        .catch((error: any)=>{
          
        })

        
    };

    const handleReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
        // The `from` and `to` properties contain the index of the item
        // when the drag started and ended, respectively
        console.log('Dragged from index', event.detail.from, 'to', event.detail.to);
    
        // Finish the reorder and position the item in the DOM based on
        // where the gesture ended. This method can also be called directly
        // by the reorder group
        event.detail.complete();

        updatePlaylist(event.detail.from, event.detail.to);
    }

    const updatePlaylist = (from: number, to: number) => {

        let playlistSongsIDs : Array<number> = playlistSongs.map((song: { id: any; category_id: any; name: boolean | null | undefined; }, i: React.Key) => {
            return song.id;
        });

        console.log(playlistSongsIDs);

        const songIDFrom = playlistSongsIDs[from];
        playlistSongsIDs.splice(from, 1);
        playlistSongsIDs.splice(to, 0, songIDFrom);

        console.log(playlistSongsIDs);

        API.post("/update/playlist", {
            id: playlist.id,
            name: playlist.name,
            date: playlist.date,
            user_id: 1, 
            songs_id: playlistSongsIDs
            })
        .then((res: any) => {
            console.log("show toast - playlist was updated!")
        })
        .catch((error: any)=>{
            
        })
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    {playlist && <IonText style={{paddingLeft: '25px'}}>{playlist.name} - {format(parseISO(playlist.date), 'd. M. yyyy - HH:mm')}</IonText>}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>
                    {playlistSongs && playlistSongs.map((song: { id: any; category_id: any; name: boolean | null | undefined; }, i: React.Key) => {
                        return (
                            <IonItemSliding key={i} id={song.id}>
                                <IonItem button={true} href={`/songs/${song.id}`} style={{borderLeft: `10px solid ${getCategoryColor(song.category_id)}`}}>
                                    <IonLabel>{`${song.name}`}</IonLabel>
                                    <IonReorder slot="end"></IonReorder>
                                </IonItem>
                                {loggedIn && <IonItemOptions side="end">
                                <IonItemOption color="danger" onClick={() => {removeSongFromPlaylist(song);}}>
                                    <IonIcon slot="start" icon={remove} />
                                    Odstrániť
                                </IonItemOption>
                                </IonItemOptions>}
                            </IonItemSliding>
                        );
                    })}
                </IonReorderGroup>
            </IonContent>
        </IonPage>
    );
};

export default withRouter(PlaylistDetail);
