import { IonCardSubtitle, IonLoading, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCardTitle, IonCard, IonCardHeader, IonFab, IonIcon, IonFabButton } from '@ionic/react';
import './Playlists.css';
import API from '../api';
import { add, trashOutline } from 'ionicons/icons';
import { RouteComponentProps, withRouter } from 'react-router';
import React, { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { DateTime } from 'luxon';

interface PlaylistsProps extends RouteComponentProps {
  playlists?: any;
}

const Playlists: React.FC<PlaylistsProps> = ({history, location, playlists}) => {
  const [loggedIn] = useLocalStorage('loggedIn', false);

  useEffect(() => {
    if(location.search) {
        const search = location.search;
        const params = new URLSearchParams(search);
        const refresh : Boolean | null = Boolean(params.get('refresh'));
        if (refresh) {
          setTimeout(() => {
            history.push({
              pathname: '/playlists',
              search: ''
            });
            window.location.reload();
          }, 1000);
        }
        
    }
  }, [location, history]);

  const deletePlaylist = (e: React.MouseEvent<HTMLIonIconElement, MouseEvent>, p: {id: number, name: string, date: string, user_id: number, songs_id: Array<any>}) => {
    e.stopPropagation();
    API.post("/delete/playlist", p)
    .then((res: any) => {
        if (res.status === 200) {
          window.location.reload();
        }
    })
    .catch((error: any)=>{
      
    })
  }

  const showPlaylist = (id: number) => {
    history.push(`/playlist/${id}`);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Playlisty</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

        <IonLoading
          cssClass='my-custom-class'
          isOpen={!playlists}
          message={'Chvíľku strpenia...'}
        />

        {playlists && playlists.map((playlist: { id: number; name: string; date: string; user_id: number; songs_id: any[]; }, i: React.Key | null | undefined) => {
              return (
                <IonCard key={i} button={true} onClick={() => {showPlaylist(playlist.id)}}>
                  <IonCardHeader>
                    <IonCardTitle>{playlist.name}</IonCardTitle>
                    <IonCardSubtitle>{DateTime.fromISO(playlist.date, {zone: 'utc'}).toFormat('d. M. yyyy - HH:mm')}</IonCardSubtitle>
                    <IonIcon icon={trashOutline} style={{position: "absolute", cursor: "pointer", fontSize: "25px", top: "25px", right: "15px"}}
                                    onClick={(e) => {deletePlaylist(e, playlist)}}></IonIcon>
                  </IonCardHeader>
                </IonCard>
              );
        })}
        {loggedIn && <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton href="/playlistadd">
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>}
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Playlists);